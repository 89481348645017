import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AccountService } from '../components/shell/account.service';
import { User, Device } from '../model/pbx.class';
import { Observable } from 'rxjs';

@Injectable()
export class PBXService {
    private headers = new HttpHeaders({
        'Authorization': 'Bearer ' + this.account.current.token,
        'Voverc-Jwt-Auth': this.account.current.jwtToken
    });
    constructor(
        private http: HttpClient,
        private account: AccountService
    ) {

    }

    getUsers(pbxId: number): Observable<User[]> {
        return this.http.get<User[]>(
            `${environment.API_URL}/api/v1/pbxes/${pbxId}/users`,
            { headers: this.headers }
        );
    }

    getDevices(pbxId: number, userId: number): Observable<Device[]> {
        return this.http.get<Device[]>(
            `${environment.API_URL}/api/v1/pbxes/${pbxId}/users/${userId}/devices`,
            { headers: this.headers }
        );
    }

} 