import { SessionDid } from './did.class'

export class Session {
    userId: number;
    companyId: number;
    pbxId: number;
    deviceId: number;
    firstName: string;
    lastName: string;
    company: string;
    extension: string;
    balance: number;
    isAdministrator: boolean;
    controlPanelUrl: string;
    isCustomerSupportEnabled: boolean;
    dids: SessionDid[];
    outboundNumber: SessionDid;
    referralLink: string;
    isReady: boolean;
    email: string;
    aggregateId: string;
}
