import {Pipe, PipeTransform} from "@angular/core";
import { en } from '../../assets/lang/lang-en';
import { it } from '../../assets/lang/lang-it';
import { LocaleService } from '../components/shell/locale.service';

@Pipe({
    name: "Translate"
})

export class TranslatePipe implements PipeTransform {
    private locales:Object;

    constructor(private locale: LocaleService){
        this.locales = {};
        this.locales['en'] = en;
        this.locales['it'] = it;
    }

    transform(value: any, args: string): any {
        let attrs = value.split('.');
        let aux;
        try{
            aux = this.locales[this.locale.current] || this.locales['it'];
            attrs.forEach((item) => { aux = aux[item] });
        }
        catch(e){
            console.log(this.locale.current);
            return value;
        }
        return aux;
    }
}