/**
 * Created by manuel on 16/11/2016.
 */
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "Filter"
})

export class FilterPipe implements PipeTransform {
    transform(value: any, args: string): any {
        let filter = args.toLowerCase();
        return filter ? value.filter(contact => contact.firstName.toLocaleLowerCase().indexOf(filter) != -1) : value;
    }
}