/**
 * Created by manuel on 11/15/16.
 */
import { Component, OnInit } from '@angular/core';
import { SessionDid } from '../../model/did.class';
import { SessionService } from '../shell/session.service';
import { AccountService } from "../shell/account.service";
import { DidsService } from './dids.service';
import { PbxDid, UIDid } from './dids.model';
import { PBXService } from 'src/app/services/pbx.service';
import { User, Device } from 'src/app/model/pbx.class';

@Component({
  selector: 'dids',
  templateUrl: './dids.component.html',
  styleUrls: ['./dids.component.scss']
})
export class DidsComponent implements OnInit {
  user: User = null;
  device: Device = null;
  session = this.Session.current;
  account = this.Account.current;
  dids: UIDid[] = this.didsService.getCachedDids();
  outboundDid: SessionDid = this.session.outboundNumber;
  countryId: number = this.session.companyId;

  constructor(
    private Session: SessionService,
    private Account: AccountService,
    private didsService: DidsService,
    private pbxService: PBXService,
  ) {
  }

  ngOnInit() {
    // TODO: replace with data from new API https://voverc.atlassian.net/browse/DEV-5760
    this.pbxService.getUsers(this.session.pbxId).subscribe(
      (res: User[]) => {
        return this.user = res.find(user => user.email === this.session.email);
      },
      () => {
      });
  }

  setOutboundDid(did: UIDid) {
    // TODO: replace with data from new API https://voverc.atlassian.net/browse/DEV-5760
    this.pbxService.getDevices(this.session.pbxId, this.user.id).subscribe(
      (res: Device[]) => {
        this.device = res.find(device => device.username === this.account.installId);
        if (did.id !== this.outboundDid.didId) {
          this.didsService
            .setOutboundDid(this.session.pbxId, this.device.id, this.user.id, did.number)
            .then(
              () => {
                const outboundDid = this.session.dids.find(d => d.number === did.number);
                this.Session.current.outboundNumber = outboundDid;
                this.outboundDid = outboundDid;
              });
        }
      },
      () => {
      });
  }
}
