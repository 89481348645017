export const en = {
    "user": {
        "tag-title": "Voverc webapp - user",
        "extension": "Extension",
        "credit": "Credit",
        "title1": "ACCOUNT",
        "contacts": "Phonebook",
        "number": "Outgoing number",
        "settings": "Control Panel",
        "title2": "SUPPORT",
        "chat": "Chat with us",
        "phone": "Call us",
        "email": "Email us",
        "tools": "Support tools",
        "bring-a-friend": "Bring a friend",
        "romulus": "Romulus inbox"
    },
    "contacts": {
        "tag-title": "Voverc webapp - phonebook",
        "title": "Phonebook",
        "tabs": {
            "internals": "Internals",
            "shared": "Shared",
            "no-results": "No contacts found",
        },
        "shared": {
            "prev": "Back",
            "next": "Next",
            "fetch": "Fetch contacts",
            "error": "There was a problem fetching shared contacts. Try again later and contact support if the problem persists."
        },
        "details": {
            "phones": {
                "title": "Phone numbers",
                "labels": {
                    "PRIMARY": "Primary",
                    "SECONDARY": "Secondary",
                    "MOBILE": "Mobile",
                    "HOME": "Home",
                    "JOB": "Job",
                    "OTHER": "Other"
                }
            },
            "emails": {
                "title": "Emails",
                "labels": {
                    "PRIMARY": "Primary",
                    "SECONDARY": "Secondary",
                    "HOME": "Home",
                    "JOB": "Job",
                    "OTHER": "Other"
                }
            },
            "country": "Country",
            "city": "City",
            "province": "Province",
            "postal-code": "Postal code",
            "street": "Street",
            "address": "Address",
            "company-name": "Company name",
            "job-title": "Job title",
            "website": "Website",
            "no-phones": "This contact doesn't have any phone number",
            "no-emails": "This contact doesn't have any email"
        }
    },
    "number": {
        "tag-title": "Voverc webapp - number",
        "number": "Outgoing number",
        "question": "Which one do you want to be your outgoing number?",
        "temporary-number-message": "This is temporary number.\nYour outgoing calls will be anonymous."
    },
    "tools": {
        "title": "Support tools",
        "renew": "Update voice profile"
    },
    "error-message": {
        "400": "We are sorry. There was an authentication error while fetching data. Please try to restart or re-login into the app.",
        "500": "We are very sorry. There was a server error. We should be already fixing it. Please try to restart or re-login into the app.",
        "problem-persists": "If the problem persists - please"
    },
    "bring-a-friend": {
        "messages": {
            "success": "Invitation link was sent successfully!",
            "error": "There was a problem sending invitation, please try again later."
        },
        "header": {
            "title": "Getting 1 month for free has never been so easy!"
        },
        "illustration": {
            "img-url": "assets/img/top_illustration_eng.png"
        },
        "steps": {
            "step-1": {
                "title": "Send the link to your friends",
                "img-url": "assets/img/icon_link.png"
            },
            "step-2": {
                "title": "Your friend accepts your invite",
                "img-url": "assets/img/icon_accept_eng.png"
            },
            "step-3": {
                "title": "You both get 1 month for free",
                "img-url": "assets/img/icon_free_month_eng.png"
            }
        },
        "share": {
            "text": "Share your referral link with friends",
            "form": {
                "errors": {
                    "required": "Required field",
                    "email": "Email not valid"
                },
                "name": {
                    "label": "Name",
                    "placeholder": "e.g. John"
                },
                "email": {
                    "label": "Email",
                    "placeholder": "john@company.com"
                },
                "button": "Send"
            }
        }
    },
    "bring-a-friend-new": {
        "disclaimer": {
            "title": "Invite a friend:",
            "subtitle": "if he will choose Voxloud, you will both earn 250€"
        },
        "steps": {
            "contact": {
              "title": "Give us the contact",
              "description": "Talk with your Specialist or fill the form and send us the contact information of whom you think can take advantage from Voxloud."
            },
            "customer": {
              "title": "Your friend becomes Voxloud’s customer",
              "description": "We will talk to your friend and we will understand if Voxloud can be his best solution."
            },
            "gift": {
              "title": "You earn the 250€ gift card!",
              "description": "Your friend becomes Voxloud’s customer and you both receive the 250€ gift card or the credit!"
            }
        },
        "contact": {
            "title": "Give us the contact: we will take care of everything else!",
            "call": {
              "text": "Call and give us your friend’s contact",
              "button": "Call"
            },
            "compile": {
              "text": "Fill the form with your friend’s contact",
              "button": "Fill"
            }
        }
    },
    "romulus": {
        "title": "Romulus inbox"
    }
};
