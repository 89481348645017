<main>
    <ul>
        <li class="user">
            <span>{{session.company | slice:'0':'2' }}</span>
            <h1>{{session.firstName}} {{session.lastName}}</h1>
            <h2>{{ 'user.extension' | Translate}} {{session.extension}} </h2>
        </li>
    </ul>

    <div id="content" class="list">
        <div class="group-title credit">{{ 'user.credit' | Translate }} <span class="pull-right">{{session.balance |
                number:'1.2-2' | currency:currency_code}}</span>
        </div>
        <div class="group-title">{{ 'user.title1' | Translate }}</div>
        <ul class="features">
            <li routerLink="/contacts">{{ 'user.contacts' | Translate }} <i class="icon-small-arrow-right pull-right"></i>
            </li>
            <li routerLink="/dids">{{ 'user.number' | Translate }} <i class="icon-small-arrow-right pull-right"></i></li>
            <li routerLink="/inbox" *ngIf="isRomulusActive">{{ 'user.romulus' | Translate }} <i class="icon-small-arrow-right pull-right"></i></li>
            <li style="padding: 0">
                <a style="display: block; width: 100%; height: 50px; line-height: 50px; padding: 0 5px;"
                    [href]="session.controlPanelUrl" *ngIf="session.isAdministrator" target="_blank">
                    {{ 'user.settings' | Translate }} <i class="icon-small-settings pull-right"></i>
                </a>
            </li>
        </ul>
        <div class="group-title">{{ 'user.title2' | Translate }}</div>
        <ul class="features">
            <li *ngIf="session.isCustomerSupportEnabled || true" (click)="openChat()">{{ 'user.chat' | Translate }} <i
                    class="icon-small-support pull-right"></i></li>
            <a [href]="sanitize('0640048260')">
                <li *ngIf="session.isCustomerSupportEnabled">{{ 'user.phone' | Translate }}<i
                        class="icon-small-handset pull-right"></i></li>
            </a>
            <a href="mailto:support@voxloud.com">
                <li>
                    {{ 'user.email' | Translate }}<i class="icon-small-mail pull-right"></i>
                </li>
            </a>
            <li routerLink="/tools">
                {{ 'user.tools' | Translate }}
                <i class="icon-small-arrow-right pull-right"></i>
            </li>
            <li *ngIf="session.referralLink" routerLink="/bring-a-friend">
                {{ 'user.bring-a-friend' | Translate }}
                <i class="bring-a-friend"></i>
                <i class="icon-small-arrow-right pull-right"></i>
            </li>
        </ul>
    </div>
</main>