export const it = {
    "user": {
        "tag-title": "Voverc webapp - utente",
        "extension": "Interno",
        "credit": "Credito residuo",
        "title1": "PROFILO",
        "contacts": "Rubrica telefonica",
        "number": "Numero in uscita",
        "settings": "Pannello di controllo",
        "title2": "SUPPORTO",
        "chat": "Chatta con noi",
        "phone": "Chiamaci",
        "email": "Mandaci una email",
        "tools": "Strumenti di supporto",
        "bring-a-friend": "Porta un amico",
        "romulus": "Romulus inbox"
    },
    "contacts": {
        "tag-title": "Voverc webapp - rubrica",
        "title": "Rubrica",
        "tabs": {
            "internals": "Interni",
            "shared": "Condivisi",
            "no-results": "Nessun contatto trovato",
        },
        "shared": {
            "prev": "Indietro",
            "next": "Avanti",
            "fetch": "Riprova",
            "error": "C'è stato un problema nel recuperare i contatti. Prova ancora più tardi o contatta il supporto se il problema persiste"
        },
        "details": {
            "phones": {
                "title": "Numeri di telefono",
                "labels": {
                    "PRIMARY": "Primario",
                    "SECONDARY": "Secondario",
                    "MOBILE": "Cellulare",
                    "HOME": "Casa",
                    "JOB": "Lavoro",
                    "OTHER": "Altro"
                }
            },
            "emails": {
                "title": "Email",
                "labels": {
                    "PRIMARY": "Primario",
                    "SECONDARY": "Secondario",
                    "HOME": "Casa",
                    "JOB": "Lavoro",
                    "OTHER": "Altro"
                }
            },
            "country": "Paese",
            "city": "Città",
            "province": "Provincia",
            "postal-code": "CAP",
            "street": "Strada",
            "address": "Indirizzo",
            "company-name": "Nome azienda",
            "job-title": "Posizione",
            "website": "Sito web",
            "no-phones": "Questo contatto non ha numeri di telefono",
            "no-emails": "Questo contatto non ha email",
            
        }
    },
    "number": {
        "tag-title": "Voverc webapp - numero",
        "number": "Numero in uscita",
        "question": "Quale numero vuoi che appaia quando chiami?",
        "temporary-number-message": "Questo è un numero temporaneo.\nLe tue chiamate in uscita saranno anonime."
    },
    "tools": {
        "title": "Strumenti di supporto",
        "title1": "PROFILO",
        "renew": "Aggiorna profilo voce"
    },
    "error-message": {
        "400": "Ci dispiace. Si è verificato un errore di autenticazione durante il recupero dei dati. Prova a riavviare l'app o ad effettuare nuovamente il login.",
        "500": "Ci dispiace molto. Si è verificato un errore del server. Siamo già al lavoro per risolverlo. Prova a riavviare l'app o ad effetuare nuovamente il login.",
        "problem-persists": "Se il problema persiste, per favore"
    },
    "bring-a-friend": {
        "messages": {
            "success": "Il link di invito è stato inviato con successo!",
            "error": "Si è verificato un problema durante l'invio dell'invito, riprova più tardi."
        },
        "header": {
            "title": "Ricevere in regalo un mese gratuito non è mai stato così semplice!"
        },
        "illustration": {
            "img-url": "assets/img/top_illustration.png"
        },
        "steps": {
            "step-1": {
                "title": "Invia il link ai tuoi amici",
                "img-url": "assets/img/icon_link.png"
            },
            "step-2": {
                "title": "Il tuo amico accetta l'invito",
                "img-url": "assets/img/icon_accept.png"
            },
            "step-3": {
                "title": "Entrambi ottenete 1 mese gratis in regalo!",
                "img-url": "assets/img/icon_free_month.png"
            }
        },
        "share": {
            "text": "Condividi il tuo link di riferimento con i tuoi amici:",
            "form": {
                "errors": {
                    "required": "Campo obbligatorio",
                    "email": "Email non valida"
                },
                "name": {
                    "label": "Nome",
                    "placeholder": "es. Mario"
                },
                "email": {
                    "label": "Email",
                    "placeholder": "mario@company.com"
                },
                "button": "Spedire"
            }
        }
    },
    "bring-a-friend-new": {
        "disclaimer": {
            "title": "Invita un amico:",
            "subtitle": "se sceglierà Voxloud, entrambi ottenete 250€"
        },
        "steps": {
            "contact": {
              "title": "Passaci il contatto",
              "description": "Parla con il tuo Specialista o compila il form e passaci il contatto di chi pensi possa trarre beneficio da Voxloud."
            },
            "customer": {
              "title": "Il tuo amico diventa cliente Voxloud",
              "description": "Parlaremo con il tuo amico e capiremo se Voxloud fa al caso suo."
            },
            "gift": {
              "title": "Ricevi la gift card da 250€!",
              "description": "Il tuo amico diventa cliente Voxloud ed entrambi ricevete la gift card o il credito da 250€!"
            }
        },
        "contact": {
            "title": "Passaci il contatto: al resto penseremo tutto noi!",
            "call": {
                "text": "Chiamaci e invia i contatti del tuo amico",
                "button": "Chiamaci"
            },
            "compile": {
                "text": "Compila il module con i contatti del tuo amico",
                "button": "Compila"
            }
        }
    },
    "romulus": {
        "title": "Romulus inbox"
    }
};
