import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AccountService } from './account.service';

@Injectable()
export class AuthService {
    private apiUrl = environment.API_URL;
    private tokenUrl = `${this.apiUrl}/api/token`;
    private tokenHeaders = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

    public headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    constructor(private http: HttpClient,
        private currentAccount: AccountService) { }

    getAuthToken(username: string, password: string): Promise<void> {
        if (this.currentAccount.current.token) {
            return Promise.resolve();
        }
        else {
            return this.http
                .post(this.tokenUrl,
                    `grant_type=password&username=${username}&password=${password}`,
                    { headers: this.tokenHeaders })
                .toPromise()
                .then((response: any) => {
                    this.headers = this.headers.append('Authorization', 'Bearer ' + response.access_token);
                    this.currentAccount.current.setToken(response.access_token);
                    this.currentAccount.current.setJWTToken(response.jwt);
                })
        }
    }
}
