import { Session } from '../../model/session.class'
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../shell/auth.service';
import { AccountService } from '../shell/account.service';
import { ChatService } from "../../services/chat.service";
import { environment } from 'src/environments/environment';

@Injectable()
export class SessionService {
    private sessionUrl = `${environment.API_URL}/api/v2/csp/session`;
    public current = new Session();

    constructor(
        private auth: AuthService,
        private currentAccount: AccountService,
        private http: HttpClient,
        private chat: ChatService) {
    }

    // TODO: replace with data from new API https://voverc.atlassian.net/browse/DEV-5760
    getSession(installid: string) {
        if (this.current.isReady) {
            return Promise.resolve(null);
        } else {
            return this.http.get(`${this.sessionUrl}/${installid}?v=${encodeURIComponent(location.href)}`, { headers: this.auth.headers })
                .toPromise()
                .then(response => {
                    this.current = response as Session;
                    this.current.isReady = true;
                    this.chat.loginUser({
                        name: `${this.current.firstName} ${this.current.lastName}`,
                        email: this.currentAccount.current.username,
                        organization: this.current.company
                    });
                })
        }
    }
}
