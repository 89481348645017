import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ContactListService } from './contact-list.service';
import { environment } from 'src/environments/environment';
import { APIAddressbookFilter, APIAddressbookRecord, DEFAULT_PAGE_SIZE, PaginatedAPIAddressbookContacts, SEARCH_CRITERIA, STARTING_PAGE, TABS } from './contact-list.model';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime, switchMap } from 'rxjs/operators';
import { Contact } from 'src/app/model/contact.class';
import { Observable, Subject, Subscription } from 'rxjs';
import { SubscriptionService } from '../shell/subscription.service';

@Component({
    selector: 'contact-list',
    templateUrl: './contact-list.component.html',
    styleUrls: ['./contact-list.component.scss']
})

export class ContactListComponent {
    contactLists = this.contactListService.currentContactList;
    letters: string[] = [];
    lists: Object = {};

    public filteredList = new Map(this.contactLists);
    public filteredListSize: number = this.countFilteredListItems();

    public form: FormGroup;
    public inputValueChangeSub: Subscription;

    // start tab variables
    public TABS = TABS;
    public currentTab: string = TABS.INTERNALS;
    public isSharedAvailable: boolean = this.subscriptionService.currentSubscription.addons.some((v) => v.id.includes('address_book'));
    // end tab

    // start shared phone book variables
    public currentPageSize = DEFAULT_PAGE_SIZE;
    public currentPage = STARTING_PAGE;
    public sharedPhonebook: PaginatedAPIAddressbookContacts;
    public currentFilters: APIAddressbookFilter = {};
    public loadingSharedContacts: boolean = false;
    public fakeValues = new Array(20);
    public fetchError: boolean = false;
    public selectedContact: APIAddressbookRecord;
    public showContactDetails: boolean = false;
    public remoteSearch$: Subject<{
        filters: APIAddressbookFilter,
        page: number,
        size: number
    }> = new Subject();
    // end shared phone book variables

    constructor(
            private contactListService: ContactListService,
            private sanitizer: DomSanitizer,
            private subscriptionService: SubscriptionService
        ) {
        let iterator = this.contactLists.keys();
        let key = iterator.next().value;
        while (key) {
            this.letters.push(key);
            this.lists[key] = this.contactLists.get(key);
            key = iterator.next().value;
        }
    }

    ngOnInit() {
        this.form = new FormGroup({
            searchInput: new FormControl('')
        });
        this.inputValueChangeSub = this.form.controls.searchInput.valueChanges.pipe(debounceTime(100)).subscribe((newValue: string) => {
            this.currentFilters = {
                input: newValue,
                properties: SEARCH_CRITERIA
            }

            if(this.currentTab === TABS.INTERNALS) {
                this.internalsSearch();
            }

            if(this.currentTab === TABS.SHARED) {
                this.remoteSearch$.next({
                    filters: this.currentFilters,
                    page: STARTING_PAGE,
                    size: DEFAULT_PAGE_SIZE
                });
                this.getSharedContacts(this.currentFilters, STARTING_PAGE, DEFAULT_PAGE_SIZE);
            }
        });
        this.remoteSearch$.pipe(switchMap((values:{
            filters: APIAddressbookFilter,
            page: number,
            size: number
        }) => {
            return this.getSharedContacts(values.filters, values.page, values.size)
        })).subscribe((res: PaginatedAPIAddressbookContacts) => {
            this.sharedPhonebook = res;
            this.loadingSharedContacts = false;
            this.fetchError = false;
        }, () => {
            this.loadingSharedContacts = false;
            this.fetchError = true;
        });
    }

    ngOnDestroy() {
        this.inputValueChangeSub?.unsubscribe();
    }

    /**
     * Skip sanitize check on CSP custom URLs
     * @param extension
     * @returns {SafeUrl}
     */
    sanitize(extension: string) {
        // temporary commented this row to bypass acrobits bug of double call
        return this.sanitizer.bypassSecurityTrustUrl(`${environment.CALL_PREFIX}${extension}?dialAction=autoCall`);
        //return this.sanitizer.bypassSecurityTrustUrl(`${this.conf.CALL_PREFIX}${extension}`);
    }

    // start tab functions
    public onChangeTab(tab: string) {
        if(tab && tab !== this.currentTab){
            this.currentTab = tab;
            this.form.controls.searchInput.setValue('');
            if(this.currentTab === TABS.SHARED) {
                this.remoteSearch$.next({
                    filters: {},
                    page: STARTING_PAGE,
                    size: DEFAULT_PAGE_SIZE
                });
            }
            if(this.currentTab === TABS.INTERNALS && this.showContactDetails) {
                this.showContactDetails = false;
            }
        }
    }
    // end tab functions

    // start shared phone book functions
    public onGoNextPage() {
        if(this.sharedPhonebook.last) return;
        this.remoteSearch$.next({
            filters: this.currentFilters, 
            page: this.sharedPhonebook.number + 1, 
            size: this.sharedPhonebook.size
        });
    }

    public onGoPrevPage() {
        if(this.sharedPhonebook.first) return;
        this.remoteSearch$.next({
            filters: this.currentFilters, 
            page: this.sharedPhonebook.number - 1, 
            size: this.sharedPhonebook.size
        });
    }

    public onRetryFetchContact(){
        this.remoteSearch$.next({
            filters: this.currentFilters, 
            page: this.sharedPhonebook?.number + 1 || STARTING_PAGE, 
            size: this.sharedPhonebook?.size || DEFAULT_PAGE_SIZE
        });
    }

    private getSharedContacts(filters: APIAddressbookFilter, page: number, size: number){
        this.loadingSharedContacts = true;
        return this.contactListService.getSharedContacts({
                ...filters,
                page,
                size
            })
    }

    public openContactDetails(contact: APIAddressbookRecord) {
        this.selectedContact = contact;
        this.showContactDetails = true;
    }

    // end shared phone book functions

    private internalsSearch() {
        this.contactLists.forEach((value: Contact[], key: string) => {
            this.filteredList.set(key, value.filter((value: Contact) => {
                return `${value.firstName} ${value.lastName}`.includes(this.currentFilters.input) ||
                        value.extension.includes(this.currentFilters.input);
            }))
            this.filteredListSize = this.countFilteredListItems();
        })
    }

    private countFilteredListItems(): number {
        let count = 0;
        this.filteredList.forEach((v) => {
            count += v.length;
        });
        return count;
    }
}