/**
 * Created by manuel on 17/11/2016.
 */
import { Injectable } from '@angular/core';
import { ChatService } from "../../services/chat.service";

@Injectable()
export class LocaleService {
    current: string;

    constructor(private chat: ChatService) {
        this.getBrowserLocale();
        this.sanitizeBrowserLocale();
    }

    private getBrowserLocale() {
        if (window.navigator['languages']) {
            this.current = window.navigator['languages'][0];
        }
        else {
            this.current = window.navigator['language'] || window.navigator['browserLanguage'] || window.navigator['userLanguage'];
        }
        let chatLocale = this.current.split('-')[0];
        this.chat.setLocale(chatLocale);
    }

    private sanitizeBrowserLocale() {
        if (this.current.indexOf('-') !== -1)
            this.current = this.current.split('-')[0];
        else
            this.current = this.current.split('_')[0];

        if (!/it|en/.test(this.current)) {
            this.current = 'en';
        }
    }
}
