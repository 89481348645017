<div class="contact-details"
  [ngClass]="{'contact-details-open': open, 'contact-details-close': !open}"
  *ngIf="contact">
  <div class="contact-details__header-container">
    <div class="contact-details__header-icon">
      <i class="icon-big-user" *ngIf="!contact?.crm_logo"></i>
      <img [src]="contact.crm_logo" *ngIf="contact?.crm_logo">
    </div>
    <div class="contact-details__header-info">
      <span class="contact-details__header-info-text">{{ contact.first_name }}</span>
      <span class="contact-details__header-info-text">{{ contact.last_name }}</span>
    </div>
    <div class="contact-details__header-actions">
      <a [href]="sanitizeLink(contact.crm_contact_url)" target="_blank" *ngIf="contact.crm_contact_url" class="contact-details__header-actions-btn">
        <i class="icon-small-join"></i>
      </a>
      <button class="contact-details__header-actions-btn">
        <i class="icon-small-close" (click)="close()"></i>
      </button>
    </div>
  </div>
  <div class="contact-details__body">
    <ul class="contact-details__body-list">

      <ng-container *ngIf="contact.phones?.length">
        <li class="contact-details__body-list-item">
          <label class="contact-details__body-list-item-label">
            {{'contacts.details.phones.title' | Translate}}
          </label>
          <ul class="contact-details__body-inner-list">
            <li class="contact-details__body-inner-list-item"  *ngFor="let phone of contact.phones">
              <div class="contact-details__body-inner-list-item-info">
                <label class="contact-details__body-inner-list-item-label">
                  {{'contacts.details.phones.labels.' + phone.label | Translate}}
                </label>
                <span class="contact-details__body-inner-list-item-value">
                  {{phone.number}}
                </span>
              </div>
              <a [href]="sanitize(phone.number)" class="contact-details__body-inner-list-item-icon">
                <i class="icon-small-handset-outline"></i>
              </a>
            </li>
          </ul>
        </li>
      </ng-container>
      <ng-container *ngIf="!contact.phones?.length">
        <li class="contact-details__body-list-item">
          <label class="contact-details__body-list-item-label">
            {{'contacts.details.phones.title' | Translate}}
          </label>
          <p class="contact-details__body-list-item-value">
            {{'contacts.details.no-phones' | Translate}}
          </p>
        </li>
      </ng-container>

      <ng-container *ngIf="contact.emails?.length">
        <li class="contact-details__body-list-item">
          <label class="contact-details__body-list-item-label">
            {{'contacts.details.emails.title' | Translate}}
          </label>
          <ul class="contact-details__body-inner-list">
            <li class="contact-details__body-inner-list-item"  *ngFor="let email of contact.emails">
              <div class="contact-details__body-inner-list-item-info">
                <label class="contact-details__body-inner-list-item-label">
                  {{'contacts.details.emails.labels.' + email.label | Translate}}
                </label>
                <span class="contact-details__body-inner-list-item-value">
                  {{email.email}}
                </span>
              </div>
              <a [href]="sanitizeEmail(email.email)" target="_blank" class="contact-details__body-inner-list-item-icon">
                <i class="icon-small-at-sign"></i>
              </a>
            </li>
          </ul>
        </li>
      </ng-container>
      <ng-container *ngIf="!contact.emails?.length">
        <li class="contact-details__body-list-item">
          <label class="contact-details__body-list-item-label">
            {{'contacts.details.emails.title' | Translate}}
          </label>
          <p class="contact-details__body-list-item-value">
            {{'contacts.details.no-emails' | Translate}}
          </p>
        </li>
      </ng-container>

      <li class="contact-details__body-list-item">
        <label class="contact-details__body-list-item-label">
          {{'contacts.details.country' | Translate}}
        </label>
        <p class="contact-details__body-list-item-value" *ngIf="contact.addresses[0]?.country; else emptyField">
          {{contact.addresses[0].country}}
        </p>
      </li>
      <li class="contact-details__body-list-item">
        <label class="contact-details__body-list-item-label">
          {{'contacts.details.city' | Translate}}
        </label>
        <p class="contact-details__body-list-item-value" *ngIf="contact.addresses[0]?.city; else emptyField">
          {{contact.addresses[0].city}}
        </p>
      </li>
      <li class="contact-details__body-list-item">
        <label class="contact-details__body-list-item-label">
          {{'contacts.details.province' | Translate}}
        </label>
        <p class="contact-details__body-list-item-value" *ngIf="contact.addresses[0]?.province; else emptyField">
          {{contact.addresses[0].province}}
        </p>
      </li>
      <li class="contact-details__body-list-item">
        <label class="contact-details__body-list-item-label">
          {{'contacts.details.street' | Translate}}
        </label>
        <p class="contact-details__body-list-item-value" *ngIf="contact.addresses[0]?.street; else emptyField">
          {{contact.addresses[0].street}}
        </p>
      </li>
      <li class="contact-details__body-list-item">
        <label class="contact-details__body-list-item-label">
          {{'contacts.details.postal-code' | Translate}}
        </label>
        <p class="contact-details__body-list-item-value" *ngIf="contact.addresses[0]?.postal_code; else emptyField">
          {{contact.addresses[0].postal_code}}
        </p>
      </li>
      <li class="contact-details__body-list-item">
        <label class="contact-details__body-list-item-label">
          {{'contacts.details.address' | Translate}}
        </label>
        <p class="contact-details__body-list-item-value" *ngIf="contact.addresses[0]?.address_line; else emptyField">
          {{contact.addresses[0].address_line}}
        </p>
      </li>

      <li class="contact-details__body-list-item">
        <label class="contact-details__body-list-item-label">
          {{'contacts.details.company-name' | Translate}}
        </label>
        <p class="contact-details__body-list-item-value" *ngIf="contact.company_name; else emptyField">
          {{contact.company_name}}
        </p>
      </li>
      <li class="contact-details__body-list-item">
        <label class="contact-details__body-list-item-label">
          {{'contacts.details.job-title' | Translate}}
        </label>
        <p class="contact-details__body-list-item-value" *ngIf="contact.job_title; else emptyField">
          {{contact.job_title}}
        </p>
      </li>
      <li class="contact-details__body-list-item">
        <label class="contact-details__body-list-item-label">
          {{'contacts.details.website' | Translate}}
        </label>
        <p class="contact-details__body-list-item-value" *ngIf="contact.website; else emptyField">
          {{contact.website}}
        </p>
      </li>

    </ul>
  </div>
</div>

<ng-template #emptyField>
  <p class="contact-details__body-list-item-value" >
    -
  </p>
</ng-template>