import { AfterViewInit, Component } from '@angular/core';

declare var hbspt: any;

@Component({
  selector: 'compile-form',
  templateUrl: './compile-form.component.html',
  styleUrls: ['./compile-form.component.scss']
})

export class CompileFormComponent implements AfterViewInit {

  ngAfterViewInit(): void {
    hbspt.forms.create({
      portalId: "2664123",
      formId: "2bfcd9b8-1db8-41be-8244-2623a4d3a697",
      target: "#hubspotForm"
    });
    window.scrollTo(0, 0);
  }
}