/**
 * Created by manuel on 17/11/2016.
 */
import {Component} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";
import { environment } from 'src/environments/environment';

@Component({
    selector: 'tools',
    templateUrl: './tools.component.html',
})

export class ToolsComponent {
    constructor(private sanitizer: DomSanitizer) { };

    sanitize(url: string){
        return this.sanitizer.bypassSecurityTrustUrl(`${environment.CMD_PREFIX}${url}`);
    }
}