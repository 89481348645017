/**
 * Created by manuel on 11/15/16.
 */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AuthService } from '../shell/auth.service';
import { AccountService } from '../shell/account.service';
import { MonolithUser, OutboundCliConfigDid, PbxDid, UIDid } from './dids.model';
import { environment } from 'src/environments/environment';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable()
export class DidsService {
  private dids: UIDid[] = [];
  private headers = new HttpHeaders({
    'Authorization': 'Bearer ' + this.account.current.token,
    'Voverc-Jwt-Auth': this.account.current.jwtToken
  });

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private account: AccountService
  ) { }

  setOutboundDid(pbxId: number, installId: number, usersId: number, number: string): Promise<void> {
    return this.http.put(
      `${environment.API_URL}/api/v1/pbxes/${pbxId}/users/${usersId}/devices/${installId}/number/${number}`,
      null, { headers: this.headers }
    ).toPromise().then(() => null)
  }

  setDIDs(dids: UIDid[]) {
    this.dids = [...dids];
  }
  getCachedDids() {
    return this.dids;
  }

  getAllowedDidsForUser(pbxId: number, companyId: number, userId: number) {
    const getMonolithUserAvailableDids = this.http.get(`${environment.API_URL}/api/v2/companies/${companyId}/users/${userId}`, 
      {headers: this.headers}
    ).pipe(map(
      (v: MonolithUser) => v.outbound_config.available_dids 
    ));

    const getPbxDids = this.http.get(
      `${environment.API_URL}/api/v1/pbxes/${pbxId}/dids`,
      { headers: this.headers }
    );

    const apiCall = forkJoin({
      dids: getPbxDids,
      availableDids: getMonolithUserAvailableDids
    });
    
    return apiCall.toPromise().then((v: {
      dids: PbxDid[],
      availableDids: OutboundCliConfigDid[]
    }) => {
      const didsArray: UIDid[] = v.availableDids.map((did) => {
        return {
          id: did.id,
          label: did.label,
          number: did.number,
          temporary: v.dids.find(d => d.id === did.id)?.temporary || false
        }
      });
      this.setDIDs(didsArray);
      return null;
    });
  }
}
