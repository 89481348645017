import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChatService } from 'src/app/services/chat.service';
import { ContactListResolve } from '../contact-list/contact-list-resolve.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  status: Object;
  constructor(
    private chat: ChatService,
    private router: Router
  ) {
    if (this.router.getCurrentNavigation() != null) {
      this.status = this.router.getCurrentNavigation().extras?.state?.codeStatus ? this.router.getCurrentNavigation().extras?.state?.codeStatus : 500;
    }
    else this.status = 500;

  }

  ngOnInit(): void {
    switch (this.status) {
      case 400:
      case 401:
      case 403:
        this.status = 400;
        break;
      case 500:
        this.status = 500;
        break;
    }
  }

  openChat() {
    this.chat.show();
  }

}
