import { Component, EventEmitter, Input, Output } from "@angular/core";
import { APIAddressbookRecord } from "../contact-list.model";
import { DomSanitizer } from "@angular/platform-browser";
import { environment } from "src/environments/environment";

@Component({
  selector: 'contact-details',
  templateUrl: './contact-details.component.html',
  styleUrls: ['./contact-details.component.scss'],

})
export class ContactDetailsComponent{

  @Input() contact: APIAddressbookRecord;
  @Input() open: boolean = false;
  @Output() closing: EventEmitter<void> = new EventEmitter();


  constructor(private sanitizer: DomSanitizer){}

  public close() {
    this.open = false;
    this.closing.emit();
  }

  public sanitize(number: string) {
    return this.sanitizer.bypassSecurityTrustUrl(`${environment.CALL_PREFIX}${number}?dialAction=autoCall`);
  }

  public sanitizeEmail(email: string) {
    return this.sanitizer.bypassSecurityTrustUrl(`mailto:${email}`);
  }

  public sanitizeLink(link: string) {
    return this.sanitizer.bypassSecurityTrustUrl(link);
  }

}