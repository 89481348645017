import { ChatService } from "../../services/chat.service";
import { Component, OnInit } from '@angular/core';
import { SessionService } from '../shell/session.service';
import { DomSanitizer } from "@angular/platform-browser";
import { environment } from 'src/environments/environment';
import { SubscriptionResponse, SubscriptionService } from "../shell/subscription.service";
import { IntegrationService } from "src/app/services/integration.service";
import { Integration } from 'voxloud-types/dist/models/integrations/index';

const PAGE_SIZE = 1000;

@Component({
    selector: 'options',
    templateUrl: './options.component.html',
    styleUrls: ['./options.component.scss']
})

export class OptionsComponent implements OnInit {
    public currency_code: string;
    session = this.currentSession.current;
    public currencySymbol: string;
    public integrations: Integration[];

    constructor(
        private currentSession: SessionService,
        private sanitizer: DomSanitizer,
        private chat: ChatService,
        private subscriptionService: SubscriptionService,
        private integrationService: IntegrationService
    ) {
    }
    ngOnInit() {
        this.subscriptionService.getSubscription().subscribe(
            (res: SubscriptionResponse) => {
                this.currency_code = res.currency_code;
                this.integrationService.getIntegrationsByFilter(
                    0,
                    PAGE_SIZE,
                    {
                        company_id: parseInt(res.company_id),
                        platform_name: 'ROMULUS',
                        enabled: true
                    }).subscribe(
                        res => {
                            this.integrations = res.content
                        },
                        () => {
                            this.integrations = [];
                        }
                    );
            },
            () => {
            });
    }

    openChat() {
        this.chat.show();
    }

    goToControlPanel() {
        window.open(this.session.controlPanelUrl, '_blank');
    }

    sanitize(url: string) {
        //return this.sanitizer.bypassSecurityTrustUrl(`${this.conf.CALL_PREFIX}${url}`);
        return this.sanitizer.bypassSecurityTrustUrl(`${environment.CALL_PREFIX}${url}?dialAction=autoCall`);
    }

    get isRomulusActive(): boolean {
        return this.integrations?.length > 0;
    }
}