import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SessionService } from './../shell/session.service';
import { AccountService } from './../shell/account.service';
import { environment } from 'src/environments/environment';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

export enum SUBSCRIPTION_STATUS {
  ACTIVE = 'active',
  FUTURE = 'future',
  IN_TRIAL = 'in_trial',
  TRIAL_ENDED = 'trial_ended',
  NON_RENEWING = 'not_renewing',
  PAUSED = 'paused',
  CANCELLED = 'cancelled',
  UNKNOWN = 'unknown',
}

export interface BillingInfo {
  vat: string;
  zip: string;
  city: string;
  province: string;
  country: string;
  taxable: true;
  first_name: string;
  last_name: string;
  company_name: string;
  is_taxable: boolean;
  address_line_1: string;
  e_invoicing_code: string;
}

export interface Plan {
  id: string;
  name: string;
  quantity: number;
  trialPlan: boolean;
  pricing_model: 'flat_fee' | 'per_unit' | 'stairstep' | 'volume' | 'tiered';
  is_trial_plan: false;
  unit_price: number;
  total_price: number;
  meta_data: Metadata;
}

export interface Metadata {
  plan_upgrade: { version: string };
  shop?: any;
  max_dids: number;
  available_payment_methods: string[];
  pbx: {
    device_types: {
      desktop_app: number;
      csp: number;
      desk_phone: number;
      sip: number;
    };
  };
  pricing_info: { commitment_days: number; default_user_count: number };
  included_addons: IncludedAddons; // TODO: finish the interface
  max_users: number;
  users: { min: number; max: number };
  traffic: {
    initial_credit: number;
    outbound: { mobile: number; countries: string[]; landline: number }[];
  };
  subscription: { 'update-flow': string };
}

export interface IncludedAddons {
  call_queue?: any;
  call_recording?: any;
  headphones_rental?: any;
  phone_rental?: any;
  channel?: any;
  number_portability?: any;
  credit_topup?: any;
  fax?: any;
  public_api?: any;
  voices_professional?: any;
  did?: any;
  crm_integrations?: any;
}

export interface AddonMetadata {
  discounted_addon2percentage: {
    [key: string]: number;
  }
  addon_country: string;
  included_addons?: { [key: string]: Addon }
  product_name: string;
  type: string;
}

export interface Addon {
  id: string;
  name: string;
  quantity: number;
  unit_price: number;
  total_price: number;
  meta_data?: AddonMetadata;
}

export interface Coupon {
  apply_count: number;
  apply_till: string | Date;
  coupon_code: string;
  coupon_id: string;
}

export interface SubscriptionResponse {
  id: string;
  company_id: string;
  chargebee_customer_id: string;
  chargebee_subscription_id: string;
  billing_info: BillingInfo;
  plan: Plan;
  addons: Addon[];
  status: SUBSCRIPTION_STATUS;
  creation_date: Date | string;
  trial_end_date: Date | string;
  activation_date: Date | string;
  next_billing_date: Date | string;
  due_invoices_count: number;
  monthly_total_cost: number;
  promotional_credit: number;
  refundable_credit: number;
  meta_data: Metadata;
  applied_coupons: Coupon[];
  currency_code?: string;
  trial_ended_without_activation: boolean;
  stripe_public_key: string;
}

@Injectable()
export class SubscriptionService {
  public session = this.Session.current;
  public currentSubscription: SubscriptionResponse;
  public companyId: number = this.session.companyId;
  private headers = new HttpHeaders({
    'Authorization': 'Bearer ' + this.account.current.token,
    'Voverc-Jwt-Auth': this.account.current.jwtToken
  });
  constructor(
    private http: HttpClient,
    private Session: SessionService,
    private account: AccountService
  ) {
  }

  public getSubscription(): Observable<SubscriptionResponse> {
    if(this.currentSubscription){
      return of(this.currentSubscription);
    } else {
      return this.http.get<SubscriptionResponse>(
        `${environment.API_URL}/api/v1/subscriptions/${this.companyId}`,
        { headers: this.headers }
      ).pipe(tap(res => {this.currentSubscription = res}));
    }
  }

  public getCompanyCountry(s: SubscriptionResponse) {
    const firstPlanWord = s.plan.id.split('-')[0];
    // we need to have a fallback for old Italian-only plans
    // e.g. start-annual-v3.
    // country code is expected to have 2 chars
    return firstPlanWord.length === 2 ? firstPlanWord : 'it';
  }
}