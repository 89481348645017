// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  API_URL: 'https://devapi.voverc.com',
  INTERCOM_ID: 'ne99i0tn',
  CALL_PREFIX: 'voxloud:',
  CMD_PREFIX: 'voxloud-p:',
  FACEBOOK_SDK: "1922328991355968",
  referralLinkTemplate: 'https://test-go.voxloud.com/activate_now?referrer_id='
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
