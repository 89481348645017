<main>
    <ul>
        <li class="title"><i class="icon-small-back pull-left" routerLink="../options"></i>{{'tools.title' | Translate}}</li>
    </ul>
    <div id="content" class="list">
        <div class="group-title">{{'tools.title1' | Translate}}</div>
        <ul class="features">
            <a [href]="sanitize('provisioning-update')">
                <li>{{ 'tools.renew' | Translate }} <i class="icon-small-handset pull-right"></i></li>
            </a>
        </ul>
    </div>
</main>
