import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router'
import { IntercomModule } from 'ng-intercom';

import { environment } from 'src/environments/environment';
import { AppComponent } from './components/shell/app.component';
import { OptionsComponent } from './components/options/options.component';
import { ContactListComponent } from './components/contact-list/contact-list.component';
import { DidsComponent } from './components/dids/dids.component';
import { ToolsComponent } from "./components/tools/tools.component";

import { AuthService } from './components/shell/auth.service';
import { OptionsResolve } from './components/options/options-resolve.service';
import { ContactListService } from './components/contact-list/contact-list.service';
import { DidsResolve } from './components/dids/dids-resolve.service';
import { ContactListResolve } from './components/contact-list/contact-list-resolve.service';
import { AccountService } from "./components/shell/account.service";
import { SessionService } from "./components/shell/session.service";
import { LocaleService } from "./components/shell/locale.service";
import { FilterPipe } from './pipes/filter.pipe';
import { TranslatePipe } from './pipes/translate.pipe';
import { BringAFriendComponent } from "./components/bring-a-friend/bring-a-friend.component";
import { ChatService } from "./services/chat.service";
import { DidsService } from './components/dids/dids.service';
import { ReferralService } from './components/bring-a-friend/services/referral.service';
import { PBXService } from './services/pbx.service';
import { SubscriptionService } from './components/shell/subscription.service';
import { AuthErrorInterceptor } from './components/shell/auth-error-interceptor';
import { ErrorComponent } from './components/error/error.component';
import {NgxSkeletonLoaderModule} from 'ngx-skeleton-loader';
import { ContactDetailsComponent } from './components/contact-list/contact-details/contact-details.component';
import { ContactUsComponent } from './components/bring-a-friend/contact-us/contact-us.component';
import { CompileFormComponent } from './components/bring-a-friend/compile-form/compile-form.component';
import { RomulusInboxComponent } from './components/romulus-inbox/romulus-inbox.component';

@NgModule({
    imports: [
        BrowserModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forRoot([
            {
                path: '',
                redirectTo: '/options',
                pathMatch: 'full'
            },
            {
                path: 'options',
                component: OptionsComponent,
                resolve: {
                    session: OptionsResolve
                }
            },
            {
                path: 'contacts',
                component: ContactListComponent,
                resolve: {
                    contactLists: ContactListResolve
                }
            },
            {
                path: 'dids',
                component: DidsComponent,
                resolve: {
                    dids: DidsResolve
                }
            },
            {
                path: 'inbox',
                component: RomulusInboxComponent,
            },
            {
                path: 'error',
                component: ErrorComponent
            },
            {
                path: 'tools',
                component: ToolsComponent
            },
            {
                path: 'bring-a-friend',
                component: BringAFriendComponent
            },
            {
                path: 'contact-us',
                component: ContactUsComponent
            },
            {
                path: 'compile-form',
                component: CompileFormComponent
            }
        ], { useHash: true }),
      IntercomModule.forRoot({
        appId: environment.INTERCOM_ID,
        updateOnRouterChange: true
      }),
      NgxSkeletonLoaderModule
    ],
    declarations: [
        AppComponent,
        ContactListComponent,
        ContactDetailsComponent,
        OptionsComponent,
        DidsComponent,
        ToolsComponent,
        BringAFriendComponent,
        FilterPipe,
        TranslatePipe,
        ErrorComponent,
        ContactUsComponent,
        CompileFormComponent,
        RomulusInboxComponent
    ],
    bootstrap: [AppComponent],
    providers: [
        AuthService,
        ChatService,
        OptionsResolve,
        ContactListResolve,
        ContactListService,
        DidsService,
        DidsResolve,
        AccountService,
        SessionService,
        LocaleService,
        TranslatePipe,
        ReferralService,
        SubscriptionService,
        PBXService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthErrorInterceptor,
          multi: true
        }
    ]
})
export class AppModule {
}
