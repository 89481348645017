import {Injectable} from '@angular/core';
import { Intercom } from 'ng-intercom';
import { environment } from 'src/environments/environment';

@Injectable()
export class ChatService {
  constructor(private intercom: Intercom) {
    this.intercom.boot({
      app_id: environment.INTERCOM_ID,
      hide_default_launcher: true,
      widget: {
        "activator": "#intercom"
      }
    });
  }

  public show = function show() {
    this.intercom.show();
  }
  public hide = function hide() {
    this.intercom.hide();
  }
  public update = function update(data) {
    this.intercom.update(data);
  }
  public loginUser = function loginUser(controlPanelSession) {
    this.update({
      email: controlPanelSession.email,
    });
  }
  public setLocale = function setLocale(locale) {
    this.update({language_override: locale});
  }

}
