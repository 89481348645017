<main>
  <ul>
    <li class="title">
      <i class="icon-small-back pull-left" routerLink="../options"></i> {{'romulus.title' | Translate}}
    </li>
  </ul>
  <ngx-skeleton-loader *ngIf="loading" [theme]="{'height': '100%', 'margin': '0', 'margin-bottom': '-10px'}"></ngx-skeleton-loader>
  <div class="romulus-main">
    <div class="romulus-main__content" *ngIf="!loading && error === '' && iframeURL !== null">
      <iframe frameborder="0" [src]="iframeURL" (error)="iframeError()"></iframe>
    </div>
    <div class="m-l-md m-t-md" *ngIf="error !== '' && !loading">
      <h4 class="vox-heading-4">Oops!</h4>
      <div class="vox-inline-message vox-inline-message_light-grey mb-2">
        <div>
          <i class="icon-small-alert"></i>
          <span *ngIf="error === 'api'">{{ 'app.romulus.token-error' | translate }}</span>
          <span *ngIf="error === 'iframe'">{{ 'app.romulus.iframe-error' | translate }}</span>
        </div>
      </div>
    </div>
  </div>
</main>
