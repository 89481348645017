import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SessionService } from '../../shell/session.service';
import { InviteEmail } from '../models/referral.models';
import { AccountService } from '../../shell/account.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class ReferralService {
  public session = this.Session.current;
  public companyId: number = this.session.companyId;
  private headers = new HttpHeaders({
    'Authorization': 'Bearer ' + this.account.current.token,
    'Voverc-Jwt-Auth': this.account.current.jwtToken
  });
  constructor(
    private http: HttpClient,
    private Session: SessionService,
    private account: AccountService
  ) {
  }

  sendInviteEmail(data: InviteEmail) {
    return this.http.post(`${environment.API_URL}/api/v1/referrers/invite-email`, data, { headers: this.headers });
  }
}