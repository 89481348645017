<main>
    <ul>
        <li class="title">
            <i class="icon-small-back pull-left" routerLink="../options"></i> {{'number.number' | Translate}}
        </li>
    </ul>
    <div id="content" class="list">
        <div class="group-title number">{{'number.question' | Translate}}</div>
        <ul>
            <li *ngFor="let did of dids" (click)="setOutboundDid(did)" class="number"
                [ngClass]="{'active': did.id === outboundDid.didId}">
                <span>{{did.label}}</span>
                <br>
                <span>{{did.number}}</span>
                <p *ngIf="did.temporary" style="white-space: pre-wrap;">{{'number.temporary-number-message' |
                    Translate}}</p>
                <i [ngClass]="did.temporary
                  ? 'icon-small-alert pull-right'
                  : 'icon-small-check pull-right'"></i>
            </li>
        </ul>
    </div>
</main>
