import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { SessionService } from '../shell/session.service';
import { DidsService } from './dids.service';

@Injectable()
export class DidsResolve implements Resolve<void>{

  constructor(
    private session: SessionService,
    private didsService: DidsService
  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // TODO: replace with data from new API https://voverc.atlassian.net/browse/DEV-5760
    return this.didsService.getAllowedDidsForUser(this.session.current.pbxId, this.session.current.companyId, this.session.current.userId);
  }
}
