<main>
    <!-- New referral section -->
    <div class="referral-page-new">
        <i class="mif-chevron-left pull-left" routerLink="../options"></i>
        <!-- <section class="referral-page__headline">
            <div class="container">
                <ul>
                    <li class="title"><i class="mif-chevron-left pull-left" routerLink="../options"></i></li>
                </ul>
            </div>
        </section> -->
        <section class="referral-page-new__illustration">
            <div class="referral-page-new__illustration-imgs">
                <img src="assets/img/referral-new-main-{{language}}.png">
            </div>
            <div class="referral-page-new__illustration-disclaimer">
                <span class="referral-page-new__illustration-disclaimer-title">
                {{'bring-a-friend-new.disclaimer.title' | Translate}}
                </span>
                <span class="referral-page-new__illustration-disclaimer-subtitle">
                {{'bring-a-friend-new.disclaimer.subtitle' | Translate}}
                </span>
            </div>
        </section>
        <section class="referral-page-new__steps">
            <div class="referral-page-new__steps-box">
              <div class="referral-page-new__steps-box-number"><span>1</span></div>
              <div class="referral-page-new__steps-box-header">
                <img src="assets/img/share-contact.png">
                <span class="referral-page-new__steps-box-header-title">
                  {{'bring-a-friend-new.steps.contact.title' | Translate}}
                </span>
              </div>
              <span class="referral-page-new__steps-box-description">
                {{'bring-a-friend-new.steps.contact.description' | Translate}}
              </span>
            </div>
            <div class="referral-page-new__steps-box">
              <div class="referral-page-new__steps-box-number"><span>2</span></div>
              <div class="referral-page-new__steps-box-header">
                <img src="assets/img/friend-accept.png">
                <span class="referral-page-new__steps-box-header-title">
                  {{'bring-a-friend-new.steps.customer.title' | Translate}}
                </span>
              </div>
              <span class="referral-page-new__steps-box-description">
                {{'bring-a-friend-new.steps.customer.description' | Translate}}
              </span>
            </div>
            <div class="referral-page-new__steps-box">
              <div class="referral-page-new__steps-box-number"><span>3</span></div>
              <div class="referral-page-new__steps-box-header">
                <img src="assets/img/wallet-euro.png">
                <span class="referral-page-new__steps-box-header-title">
                  {{'bring-a-friend-new.steps.gift.title' | Translate}}
                </span>
              </div>
              <span class="referral-page-new__steps-box-description">
                {{'bring-a-friend-new.steps.gift.description' | Translate}}
              </span>
            </div>
        </section>
        <section class="referral-page-new__contact">
            <div class="referral-page-new__contact-title">
              {{'bring-a-friend-new.contact.title' | Translate}}
            </div>
            <div class="referral-page-new__contact-box">
              <div class="referral-page-new__contact-box-call">
                <span class="referral-page-new__contact-box-call-text">
                  {{'bring-a-friend-new.contact.call.text' | Translate}}
                </span>
                <button class="referral-page-new__contact-box-call-button vox-btn vox-btn-secondary"
                routerLink="/contact-us">
                  {{'bring-a-friend-new.contact.call.button' | Translate}}
                </button>
              </div>
              <div class="referral-page-new__contact-box-compile">
                <span class="referral-page-new__contact-box-compile-text">
                  {{'bring-a-friend-new.contact.compile.text' | Translate}}
                </span>
                <button class="referral-page-new__contact-box-compile-button vox-btn vox-btn-secondary"
                routerLink="/compile-form">
                  {{'bring-a-friend-new.contact.compile.button' | Translate}}
                </button>
              </div>
            </div>
        </section>
    </div>
        <!-- Old referral section -->
    <!-- <div class="referral-page">
        <section class="referral-page__headline">
            <div class="container">
                <ul>
                    <li class="title"><i class="icon-small-back pull-left" routerLink="../options"></i>{{'bring-a-friend.header.title' | Translate}}</li>
                </ul>
            </div>
        </section>
        <section class="referral-page__illustration">
            <div class="container">
                <div class="referral-page__illustration-wrapper">
                    <img class="img-responsive" [src]="'bring-a-friend.illustration.img-url'| Translate" alt="Illustration">
                </div>
                <hr>
            </div>
        </section>
        <section class="referral-page__steps">
            <div class="container">
                <div class="referral-page__step">
                    <img class="img-responsive" [src]="'bring-a-friend.steps.step-1.img-url'| Translate" alt="">
                    <span>{{'bring-a-friend.steps.step-1.title' | Translate}}</span>
                </div>
                <div class="referral-page__step">
                    <img class="img-responsive" [src]="'bring-a-friend.steps.step-2.img-url'| Translate" alt="">
                    <span>{{'bring-a-friend.steps.step-2.title' | Translate}}</span>
                </div>
                <div class="referral-page__step">
                    <img class="img-responsive" [src]="'bring-a-friend.steps.step-3.img-url'| Translate" alt="">
                    <span>{{'bring-a-friend.steps.step-3.title' | Translate}}</span>
                </div>
            </div>
        </section>
        <section class="referral-page__share">
            <div class="container">
                <span class="referral-page__share-title">{{'bring-a-friend.share.text' | Translate}}</span>
                <div class="referral-page__share-link">
                    <input type="url" readonly [value]="referralLink" #linkInput>
                    <button type="button" [ngClass]="classIcon" (click)="copyLink(linkInput)"></button>
                </div>
                <ul class="referral-page__social-share">
                    <li>
                        <a href="https://www.facebook.com/sharer/sharer.php?u={{linkInput.value}}" target="_blank">
                            <i class="icon-small-facebook" aria-hidden="true"></i>
                        </a>
                    </li>
                    <li>
                        <a href="https://twitter.com/intent/tweet?url={{linkInput.value}}" target="_blank">
                            <i class="icon-small-twitter" aria-hidden="true"></i>
                        </a>
                    </li>
                    <li>
                        <a href="https://www.linkedin.com/shareArticle?mini=true&url={{linkInput.value}}" target="_blank">
                            <i class="icon-small-linkedin" aria-hidden="true"></i>
                        </a>
                    </li>
                    <li>
                        <button type="button" (click)="showForm()">
                            <i class="icon-small-mail" aria-hidden="true"></i>
                        </button>
                    </li>
                </ul>
                <form *ngIf="shownForm" class="share-email-form" [formGroup]="form">
                    <div class="form-group">
                        <label>{{'bring-a-friend.share.form.name.label' | Translate}}</label>
                        <div class="vox-input-group"
                        [ngClass]="{ 'vox-input-group_invalid': form.controls.friendName.invalid && (form.controls.friendName.dirty || form.controls.friendName.touched) }"
                        >
                            <input formControlName="friendName" [placeholder]="'bring-a-friend.share.form.name.placeholder' | Translate">
                        </div>
                    </div>
                    <div class="form-group">
                        <label>{{'bring-a-friend.share.form.email.label' | Translate}}</label>
                        <div class="vox-input-group"
                        [ngClass]="{ 'vox-input-group_invalid': form.controls.friendEmail.invalid && (form.controls.friendEmail.dirty || form.controls.friendEmail.touched) }"
                        >
                            <input formControlName="friendEmail" [placeholder]="'bring-a-friend.share.form.email.placeholder' | Translate">
                        </div>
                    </div>
                    <button type="submit" [disabled]="!form.valid || loader" (click)="sendInviteEmail()">
                        <i *ngIf="loader" class="mif-spinner4" aria-hidden="true"></i>
                        <span *ngIf="!loader">{{'bring-a-friend.share.form.button' | Translate}}</span>
                    </button>
                </form>
                <p *ngIf="successMessage" class="status-message status-message--success">
                    <i class="icon-small-check" aria-hidden="true"></i>
                    {{'bring-a-friend.messages.success' | Translate}}
                    <i class="icon-small-close" aria-hidden="true" (click)="successMessage = false"></i>
                </p>
                <p *ngIf="errorMessage" class="status-message status-message--error">
                    <i class="icon-small-alert" aria-hidden="true"></i>
                    {{'bring-a-friend.messages.error' | Translate}}
                    <i class="icon-small-close" aria-hidden="true" (click)="errorMessage = false"></i>
                </p>
            </div>
        </section>
    </div> -->
</main>
