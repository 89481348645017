/**
 * Created by manuel on 11/15/16.
 */
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { ContactListService } from './contact-list.service';
import { SessionService } from '../shell/session.service';
import { Observable, forkJoin } from 'rxjs';
import { ContactsResponse } from 'src/app/model/contact.class';
import { SubscriptionResponse, SubscriptionService } from '../shell/subscription.service';

@Injectable()
export class ContactListResolve implements Resolve<[SubscriptionResponse, ContactsResponse]>{
    constructor(
        private contactListService: ContactListService,
        private subscriptionService: SubscriptionService,
        private session: SessionService
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<[SubscriptionResponse, ContactsResponse]> {
        return forkJoin([
            this.subscriptionService.getSubscription(),
            this.contactListService.getContacts(this.session.current.pbxId)
        ])
    }
}