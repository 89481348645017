<main>
    <ul>
        <li class="title"><i class="icon-small-back pull-left" routerLink="../options"></i>{{'contacts.title' |
            Translate}}</li>
        <li class="tab-container" *ngIf="isSharedAvailable">
            <div class="tab" [ngClass]="{'active': currentTab === 'internals'}" (click)="onChangeTab('internals')">
                {{'contacts.tabs.internals' | Translate}}
            </div>
            <div class="tab" [ngClass]="{'active': currentTab === 'shared'}" (click)="onChangeTab('shared')">
                {{'contacts.tabs.shared' | Translate}}
            </div>
        </li>
        <li>
            <form id="search" [formGroup]="form">
                <input type="text" formControlName="searchInput" (keyup)="0">
            </form>
        </li>
    </ul>

        <!-- Start internal phone book -->
        <ng-container *ngIf="currentTab === TABS.INTERNALS">
            <div id="content" class="list list-internal m-t-sm">
                <ng-container *ngIf="filteredListSize > 0">
                    <span class="letter-container" *ngFor="let letter of letters">
                        <div class="group-title" *ngIf="filteredList.get(letter).length > 0">{{letter}}</div>
                        <ul>
                            <a [href]="sanitize(contact.extension)"
                                *ngFor="let contact of filteredList.get(letter)">
                                <li [ngClass]="{'group': contact.type == 2, 'utente': contact.type == 1}">
                                    <i class="icon-big-user"></i><strong class="contact-name">{{contact.firstName}}
                                        {{contact.lastName}}</strong> <span class="vox-chip vox-chip_royal-blue-text">{{contact.extension}}</span>
                                </li>
                            </a>
                        </ul>
                    </span>
                </ng-container>
                <ng-container *ngIf="filteredListSize === 0">
                    <ul>
                        <li class="no-results-item">
                            <strong>
                                {{'contacts.tabs.no-results' | Translate}}
                            </strong>
                        </li>
                    </ul>
                </ng-container>
            </div>
        </ng-container>
        <!-- End internal phone book -->
        <!-- Start shared phone book -->
        <ng-container *ngIf="currentTab === TABS.SHARED">
            <div id="content" class="list m-t-sm">
                <ng-container *ngIf="!fetchError">
                    <ul *ngIf="!loadingSharedContacts && sharedPhonebook?.content.length > 0">
                        <li *ngFor="let contact of sharedPhonebook.content" (click)="openContactDetails(contact)">
                            <i class="icon-big-user" *ngIf="!contact?.crm_logo"></i>
                            <img [src]="contact.crm_logo" *ngIf="contact?.crm_logo">
                            <strong *ngIf="contact.first_name || contact.last_name" class="contact-name">{{contact.first_name}}{{contact?.last_name ? ' ' + contact.last_name : ''}}</strong>
                        </li>
                    </ul>

                    <ul *ngIf="!loadingSharedContacts && sharedPhonebook?.content.length === 0">
                        <li class="no-results-item">
                            <strong>
                                {{'contacts.tabs.no-results' | Translate}}
                            </strong>
                        </li>
                    </ul>
                    <!-- SKELETON LOADER -->
                    <ul *ngIf="loadingSharedContacts">
                        <li *ngFor="let idx of fakeValues">
                            <ngx-skeleton-loader class="loader" [theme]="{
                                'width.%': 100,
                                'height.px': 20,
                                'margin-bottom.px': 0
                            }"></ngx-skeleton-loader>
                        </li>
                    </ul>
                    <!-- END SKELETON LOADER -->
                    <div class="pagination-container">
                      <button class="pagination-btn pagination-prev"
                        (click)="onGoPrevPage()" [disabled]="loadingSharedContacts || sharedPhonebook?.first">
                        <i class="icon-small-arrow-left"></i>
                      </button>
                      <button class="pagination-btn pagination-next"
                        (click)="onGoNextPage()"
                        [disabled]="loadingSharedContacts || sharedPhonebook?.last">
                        <i class="icon-small-arrow-right"></i>
                      </button>
                    </div>

                </ng-container>
                <ng-container *ngIf="fetchError">
                    <div class="error-container">
                        <p class="error-message">
                            <span>
                                {{'contacts.shared.error' | Translate}}
                            </span>
                        </p>
                        <button class="error-btn" (click)="onRetryFetchContact()">
                            {{'contacts.shared.fetch' | Translate}}
                        </button>
                    </div>
                </ng-container>
            </div>
        </ng-container>
        <!-- End shared phone book -->


        <!-- Start contact details -->
        <contact-details [contact]="selectedContact" [open]="showContactDetails" (closing)="showContactDetails = false"></contact-details>
        <!-- End contact details -->
</main>
