import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ReferralService } from './services/referral.service';
import { SessionService } from '../shell/session.service';
import { environment } from 'src/environments/environment';
import { LocaleService } from '../shell/locale.service';

@Component({
    selector: 'bring-a-friend',
    templateUrl: './bring-a-friend.component.html',
    styleUrls: ['./bring-a-friend.component.scss']
})

export class BringAFriendComponent implements OnInit {
    public loader: boolean = false;
    public successMessage: boolean = false;
    public errorMessage: boolean = false;
    public referralLink: string;
    public classIcon: string = 'mif-clipboard';
    public shownForm: boolean = false;
    public form: UntypedFormGroup;
    public session = this.Session.current;
    public companyId: number = this.session.companyId;
    public userFullName: string = this.session.firstName + " " + this.session.lastName;
    public language: string;
    constructor(
        private Session: SessionService,
        private referralService: ReferralService,
        private formBuilder: UntypedFormBuilder,
        private locale: LocaleService
    ) {
    };

    ngOnInit() {
        this.form = this.formBuilder.group({
            companyId: [this.companyId],
            friendName: [null, [Validators.required, , Validators.minLength(2)]],
            friendEmail: [null, [Validators.required, Validators.pattern('^[^\\s@]+@[^\\s@]+\\.[^\\s@]{2,}$'), Validators.minLength(5)]],
            referrerName: [this.userFullName]
        });
        this.referralLink = environment.referralLinkTemplate + this.companyId;
        // Get language
        this.language = this.locale.current;
    }

    copyLink(inputElement: HTMLInputElement) {
        inputElement.select();
        document.execCommand('copy');
        inputElement.setSelectionRange(0, 0);
        this.classIcon = 'icon-small-check';
        setTimeout(() => {
            this.classIcon = 'mif-clipboard';
        }, 3000);
    }

    showForm() {
        this.shownForm = true;
    }

    public sendInviteEmail() {
        this.errorMessage = false;
        this.successMessage = false;
        this.loader = true;
        this.referralService.sendInviteEmail(this.form.value).subscribe(
            response => {
                this.successMessage = true;
                this.loader = false;
            },
            () => {
                this.errorMessage = true;
                this.loader = false;
            }
        )
    }

    public contactUs() {}

    public compileFormModule() {}
}