export enum TABS {
  INTERNALS = 'internals',
  SHARED = 'shared'
}

export const DEFAULT_PAGE_SIZE: number = 20;
export const STARTING_PAGE: number = 0;

export enum APIAddressbookSearchProperties {
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
  PHONE_NUMBER = 'PHONE_NUMBER',
  EMAIL = 'EMAIL',
  JOB_TITLE = 'JOB_TITLE',
  COMPANY_NAME = 'COMPANY_NAME',
  WEBSITE = 'WEBSITE'
}

export const SEARCH_CRITERIA: APIAddressbookSearchProperties[] = [
  APIAddressbookSearchProperties.FIRST_NAME,
  APIAddressbookSearchProperties.LAST_NAME,
  APIAddressbookSearchProperties.PHONE_NUMBER,
  APIAddressbookSearchProperties.EMAIL
]

export interface APIAddressbookRecord {
  id?: number;
  first_name: string;
  last_name: string;
  emails: APIAddressbookRecordEmail[];
  phones: APIAddressbookRecordPhone[];
  addresses: APIAddressRecord[];
  job_title?: string;
  company_name?: string;
  website?: string;
  crm_name?: string;
  crm_contact_url?: string;
  crm_logo?: string;
  created_at?: Date;
  updated_at?: Date;
}

export interface PaginatedAPIAddressbookContacts extends PaginatedResponse {
  content: APIAddressbookRecord[];
}

export interface APIAddressbookRecordEmail {
  id?: number;
  email: string;
  label: AddressbookRecordEmailLabel;
}

export interface APIAddressbookRecordPhone {
  id?: number;
  number: string;
  label: AddressbookRecordPhoneLabel
}

export interface APIAddressRecord {
  id?: number;
  street: string;
  postal_code: string;
  city: string;
  province: string;
  country: string;
  address_line: string;
}

export enum AddressbookRecordEmailLabel {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  HOME = 'HOME',
  JOB = 'JOB',
  OTHER = 'OTHER'
}


export enum AddressbookRecordPhoneLabel {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  MOBILE = 'MOBILE',
  HOME = 'HOME',
  JOB = 'JOB',
  OTHER = 'OTHER'
}

export enum APIAddressbookSources {
  INTERNAL = 'INTERNAL',
  PUBLIC_API = 'PUBLIC_API',
  IMPORT = 'IMPORT',
  INTEGRATION = 'INTEGRATION'
}

export interface APIAddressbookFilter {
  first_name?: string;
  last_name?: string;
  company_id?: number;
  crm_contact_id?: string;
  phone?: string;
  email?: string;
  job_title?: string;
  company_name?: string;
  website?: string;
  crm_name?: string;
  crm_contact_url?: string;
  source?: APIAddressbookSources;
  street?: string;
  postal_code?: string;
  city?: string;
  province?: string;
  country?: string;
  create_at_range?: {
    from: Date;
    to: Date;
  },
  updated_at_range?: {
    from: Date;
    to: Date;
  }
  page?: number;
  size?: number;
  input?: string;
  properties?: APIAddressbookSearchProperties[];
  ids?: string[];
}

export interface PaginatedResponse {
  total_pages: number;
  total_elements: number;
  pageable: PageableObject;
  sort: PaginationSort;
  first: boolean;
  last: boolean;
  number: number;
  number_of_elements: number;
  size: number;
  empty: boolean;
}

export interface PageableObject {
  page_number: number;
  page_size: number;
  sort: PaginationSort;
  paged: boolean;
  unpaged: boolean;
  offset: number;
}

export interface PaginationSort {
  sorted: boolean;
  unsorted: boolean;
  empty: boolean;
}