import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IntegrationsFilters, IntegrationsResponse } from 'voxloud-types/dist/models/integrations/index';
import { TokenResponse } from '../model/integration.model';
import { environment } from 'src/environments/environment';
import { AccountService } from '../components/shell/account.service';
import { Integration } from 'voxloud-types/dist/models/integrations/index';
import { SessionService } from '../components/shell/session.service';


@Injectable({
  providedIn: 'root'
})
export class IntegrationService {
  private headers = new HttpHeaders({
    'Authorization': 'Bearer ' + this.account.current.token,
    'Voverc-Jwt-Auth': this.account.current.jwtToken
  });
  constructor(
    private http: HttpClient,
    private account: AccountService,
    private currentSession: SessionService,
  ) { }

  getIntegrationsByFilter(idPage: number, size: number, filters: IntegrationsFilters): Observable <IntegrationsResponse> {
    let params = new HttpParams()
    .append('page', idPage)
    .append('size', size)

    if(filters && filters?.company_id) {
      params = params.append('company_id', filters.company_id)
    }
    if(filters && filters?.status) {
      params = params.append('status', filters.status)
    }
    if(filters && filters?.platform_id) {
      params = params.append('platform_id', filters.platform_id)
    }
    if(filters && filters?.platform_name) {
      params = params.append('platform_name', filters.platform_name)
    }
    if(filters && filters?.enabled) {
      params = params.append('enabled', filters.enabled)
    }

    return this.http.get<IntegrationsResponse>(`${environment.API_URL}/api/v1/integrations`, { params, headers: this.headers });
  }

  getIframeToken(filters: IntegrationsFilters) {
    let params = new HttpParams()
    let userId = this.currentSession.current.aggregateId;

    this.headers.append('Voverc-Company-ID', this.currentSession.current.companyId.toString());

    if(filters && filters?.platform_name) {
      params = params.append('platform_name', filters.platform_name)
    }
    return this.http.get<TokenResponse>(`${environment.API_URL}/api/v1/integrations/users/${userId}/token`, { params, headers: this.headers });
  }
}
