import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { Session } from '../../model/session.class'
import { AuthService } from '../shell/auth.service';
import { AccountService } from '../shell/account.service';
import { SessionService } from '../shell/session.service';

@Injectable()
export class OptionsResolve implements Resolve<Session>{
    private parseQueryString(qs: string) {
        let query = {};
        let a = qs.substr(1).split('&');
        for (let i = 0; i < a.length; i++) {
            let b = a[i].split('=');
            query[String(b[0])] = b[1] ? String(b[1]) : '';
        }
        return query;
    }

    constructor(
        private auth: AuthService,
        private currentAccount: AccountService,
        private currentSession: SessionService) { }

    resolve(route: ActivatedRouteSnapshot): Promise<any> {
        let params = this.parseQueryString(location.search);
        return this.auth.getAuthToken(params['cloud_username'], params['cloud_password'])
            .then(() => {
                this.currentAccount.current.setAccount(params['cloud_username'], params['cloud_password'], params['installid']);
                return this.currentSession.getSession(params['installid']);
            });
    }

}