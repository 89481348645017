/**
 * Created by manuel on 14/11/2016.
 */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Contact, ContactsResponse } from '../../model/contact.class';
import { AuthService } from '../shell/auth.service';
import { environment } from 'src/environments/environment';
import { AccountService } from '../shell/account.service';
import { of, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { APIAddressbookFilter, PaginatedAPIAddressbookContacts } from './contact-list.model';
import { SessionService } from '../shell/session.service';

@Injectable()
export class ContactListService {
    public currentContactList: Map<string, Contact[]>;
    private headers = new HttpHeaders({
        'Authorization': 'Bearer ' + this.account.current.token,
        'Voverc-Jwt-Auth': this.account.current.jwtToken
    });
    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private account: AccountService,
        private session: SessionService
    ) { }

    sortContacts(list: Contact[]): Map<string, Contact[]> {
        let sorted = new Map<string, Contact[]>();
        let sortByName = function (a, b) {
            return a.firstName.toUpperCase() == b.firstName.toUpperCase() ? 0 : +(a.firstName.toUpperCase() > b.firstName.toUpperCase()) || -1;
        };

        list.sort(sortByName);

        list.forEach((item) => {
            let firstLetter = String(item.firstName[0].toUpperCase());

            if (!sorted.get(firstLetter)) {
                let blank: Contact[] = [];
                sorted.set(firstLetter, blank);
            }

            sorted.get(firstLetter).push(item);
        });

        /**
         * Sort contacts per letter
         */
        for (let key in sorted) {
            sorted[key].sort();
        }

        return sorted;
    }

    getContacts(pbxId: number): Observable<ContactsResponse> {
        if (this.currentContactList) {
            return of(null);
        }
        else {
            return this.http.get(
                `${environment.API_URL}/api/v1/pbxes/${pbxId}/address-book`,
                { headers: this.headers }
            ).pipe(
                map((response: ContactsResponse) => {
                    let list = response.users;
                    this.currentContactList = this.sortContacts(list);
                    return null;
                }));
        }
    }

    public getSharedContacts(filters: APIAddressbookFilter): Observable<PaginatedAPIAddressbookContacts>{
        return this.http.post<PaginatedAPIAddressbookContacts>(`${environment.API_URL}/api/v2/contacts/search`, filters, {
            headers: this.headers.append('Voverc-Company-ID', String(this.session.current.companyId))
        });
    }
}