import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { IntegrationService } from 'src/app/services/integration.service';

@Component({
  selector: 'app-romulus-inbox',
  templateUrl: './romulus-inbox.component.html',
  styleUrls: ['./romulus-inbox.component.scss']
})
export class RomulusInboxComponent implements OnInit {
  constructor(
    private integrationService: IntegrationService,
    private sanitizer: DomSanitizer
  ) {}
  public iframeURL: SafeResourceUrl
  public loading: boolean = false;
  public loadingFrame: boolean = false;
  public error: 'api' | 'iframe' | '' = '';

  ngOnInit(): void {
    this.loading = true;
    this.integrationService.getIframeToken({ platform_name: 'ROMULUS'}).subscribe(res => { 
      this.loading = false
      this.iframeURL = this.sanitizer
        .bypassSecurityTrustResourceUrl(
          `https://chat.romulus.live/api-login?token=${res.token}`
        );
    },
    () => {
      this.error = 'api';
    });
  }

  iframeError() {
    this.error = 'iframe';
  }
}
